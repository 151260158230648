define([
    'lodash',
    'warmupUtilsLib',
    'warmupUtils/core/layoutUtils',
    'warmupUtils/core/warmupMenuUtils',
    'experiment'
], (
    _,
    warmupUtilsLib,
    layoutUtils,
    warmupMenuUtils,
    experiment
) => {
    'use strict';

    return siteAPI => {
        const siteData = siteAPI.getSiteData();
        return {
            siteId: siteData.siteId,
            getViewMode: () => siteData.getViewMode(),
            dataTypes: siteData.dataTypes,
            isMobileView: () => siteData.isMobileView(),
            isMobileDevice: () => siteData.isMobileDevice(),
            get isMobileZoomed() { return siteData.mobile.isZoomed(); },
            isViewerMode: () => siteData.isViewerMode(),
            get devicePixelRatio() { return siteData.mobile.getDevicePixelRatio(); },
            isTouchDevice: () => siteData.isTouchDevice(),
            isTabletDevice: () => siteData.isTabletDevice(),
            documentType: siteData.rendererModel.siteInfo.documentType,
            renderFixedPositionBackgrounds: siteData.renderFlags.renderFixedPositionBackgrounds,
            get primaryPageBackground() {
                const pageId = this.getPrimaryPageId();
                return _.get(siteData.getDataByQuery(pageId, pageId), ['pageBackgrounds', this.isMobileView() ? 'mobile' : 'desktop'], {});
            },
            getClientSpecMap: () => siteData.getClientSpecMap(),
            browser: siteData.browser,
            os: siteData.os,
            biData: siteData.biData,
            wixBiSession: siteData.wixBiSession,
            renderFlags: siteData.renderFlags,
            get measureMap() { return siteData.measureMap; },
            set measureMap(measureMap) { siteData.measureMap = measureMap; },
            tpasRenderedInSsr: siteData.tpasRenderedInSsr,
            currentUrl: siteData.currentUrl,
            anchorsMap: siteData.anchorsMap,
            rendererModel: {
                clientSpecMap: siteData.getClientSpecMap(),
                runningExperiments: siteData.getRunningExperiments(),
                siteInfo: {
                    documentType: siteData.rendererModel.siteInfo.documentType
                }
            },
            requestModel: {
                userAgent: siteData.requestModel.userAgent
            },
            getPrimaryPageId: () => siteData.getPrimaryPageId(),
            getDataByQuery: siteData.getDataByQuery.bind(siteData),
            getClientSpecMapEntry: siteData.getClientSpecMapEntry.bind(siteData),
            getMediaFullStaticUrl: siteData.getMediaFullStaticUrl.bind(siteData),
            loadImage: siteData.imageLoader.loadImage,
            get siteWidth() { return siteData.getSiteWidth(); },
            get siteX() { return siteData.getSiteX(); },
            isMesh: layoutUtils.getLayoutMechanism(siteData) === warmupUtilsLib.siteConstants.LAYOUT_MECHANISMS.MESH,
            isExperimentOpen: experimentId => experiment.isOpen(experimentId, siteData),
            getMenuById: menuId => warmupMenuUtils.getMenuById(menuId, siteData, true, {}, false),
            getZoomedImageId: () => {
                const zoomedImageData = siteData.getNonPageItemZoomData();
                return siteData.getExistingRootNavigationInfo(siteData.getFocusedRootId()).pageItemId || zoomedImageData && zoomedImageData.id; // eslint-disable-line no-mixed-operators
            },
            getZoomedImageCompData: imageId => siteData.getNonPageItemZoomData() || siteData.getDataByQuery(imageId),
            getSiteMemberDetails: () => siteData.getSiteMemberDetails(),
            getStructureCompId: () => siteData.getStructureCompId(),
            get pageMinHeight() { return siteData.getPageMinHeight(); },
            _forceMobileView: siteData._forceMobileView,
            showmobileview: siteData.showmobileview,
            isMobileOptimizedOn: () => siteData.isMobileOptimizedOn(),
            isFacebookSite: () => siteData.isFacebookSite(),
            getScreenWidth: () => siteData.getScreenWidth(),
            getSiteStructure: () => siteData.getSiteStructure(),
            getCurrentUrlPageId: () => siteData.getCurrentUrlPageId(),
            getCurrentPopupId: siteData.getCurrentPopupId.bind(siteData),
            isPageLandingPage: siteData.isPageLandingPage.bind(siteData),
            pagesData: siteData.pagesData,
            pagesDataRaw: siteData.pagesDataRaw,
            WIX_ADS_ID: siteData.WIX_ADS_ID,
            MASTER_PAGE_ID: siteData.MASTER_PAGE_ID,
            getExistingRootNavigationInfo: rootId => siteData.getExistingRootNavigationInfo(rootId),
            shouldShowWixAds: siteData.shouldShowWixAds.bind(siteData),
            getRootNavigationInfo: () => siteData.getRootNavigationInfo(),
            browserFlags: () => siteData.browserFlags(),
            getSiteWidth: () => siteData.getSiteWidth(),
            isQaMode: siteData.isQaMode.bind(siteData),
            ssr: {
                shouldRenderPage: siteData.ssr.shouldRenderPage,
                aspectsComponentStructures: siteData.ssr.aspectsComponentStructures
            },
            isFirstRenderAfterSSR: () => siteData.isFirstRenderAfterSSR(),
            forceBI: siteData.forceBI,
            getStaticMediaUrl: () => siteData.getStaticMediaUrl(),
            getMediaRichTextInnerComponents: id => siteData.getMediaRichTextInnerComponents(id), // wixapps layout only
            mobile: {
                getDevicePixelRatio: () => siteData.mobile.getDevicePixelRatio(),
                getScreenDimensions: () => siteData.mobile.getScreenDimensions(),
                isAndroidMobileDevice: () => siteData.mobile.isAndroidMobileDevice()
            },
            imageResizeHandlers: siteData.imageResizeHandlers, // get and set

            getNonPageItemZoomData: () => siteData.getNonPageItemZoomData(),
            getFocusedRootId: () => siteData.getFocusedRootId(),
            getUrlFormat: () => siteData.getUrlFormat(),
            getPagesDataItems: () => siteData.getPagesDataItems(),
            getExternalBaseUrl: () => siteData.getExternalBaseUrl(),
            isPermalink: id => siteData.isPermalink(id),
            routers: siteData.routers,
            getMainPageId: () => siteData.getMainPageId(),
            getCustomUrlMapping: () => siteData.getCustomUrlMapping(),
            getPublicBaseUrl: () => siteData.getPublicBaseUrl(),
            isUsingUrlFormat: format => siteData.isUsingUrlFormat(format),
            isFeedbackEndpoint: () => siteData.isFeedbackEndpoint(),
            isSiteHistoryEndpoint: () => siteData.isSiteHistoryEndpoint(),
            isPremiumDomain: () => siteData.isPremiumDomain(),
            getServiceTopologyProperty: prop => siteData.getServiceTopologyProperty(prop),
            isWixSite: () => siteData.isWixSite(),
            isTemplate: () => siteData.isTemplate(),
            getUnicodeExternalBaseUrl: () => siteData.getUnicodeExternalBaseUrl(),
            getLanguageCode: () => siteData.getLanguageCode(),
            getSiteX: () => siteData.getSiteX(),
            getRequestedLayoutMechanism: () => siteData.getRequestedLayoutMechanism(),
            getMasterPageLayoutSettings: () => siteData.getMasterPageLayoutSettings(),
            getCookie: () => siteData.getCookie(),
            getGlobalImageQuality: () => siteData.getGlobalImageQuality(), //wixapps layout only
            getBodyClientWidth: () => siteData.getBodyClientWidth(),
            getWixTopAdHeight: () => siteData.getWixTopAdHeight(),
            originalValuesMap: siteData.originalValuesMap,
            imageLoader: siteData.imageLoader,
            isDebugMode: () => siteData.isDebugMode(),
            getLayoutFunc: domNode => siteData.getLayoutFunc(domNode),
            getPageMargins: () => siteData.getPageMargins(), //wixapps layout only
            getAllPossiblyRenderedRoots: () => siteData.getAllPossiblyRenderedRoots(),
            getMetaSiteId: () => siteData.getMetaSiteId(),
            getPageData: (pageId, innerPath, noClone) => siteData.getPageData(pageId, innerPath, noClone),
            serviceTopology: {
                biServerUrl: siteData.serviceTopology.biServerUrl
            },
            isClientAfterSSR: () => siteData.isClientAfterSSR(),
            // siteAPI
            getLayoutMechanism() { return layoutUtils.getLayoutMechanism(siteData); },
            getAllRenderedRootIds() { return siteAPI.getAllRenderedRootIds(); },
            isSiteBusyIncludingTransition() { return siteAPI.isSiteBusyIncludingTransition(); }
        };
    };
});
